<template>
  <div class="container product-page col-md-7 p-3 desktopMargins" v-if="product">
    <div class="row d-md-none"></div>

    <div class="row">
      <div class="col-12 col-md-6">
        <img :src="mainImage" alt="Product Image" :style="product.images.length < 2 ? { height: '28rem' } : {}"
          class="product-image pi-styled mb-4 p-md-3" @click="openImageModal" />
        <div v-if="product.images.length > 1" class="image-thumbnails">
          <img v-for="(image, index) in product.images" :key="index" :src="image.image_url"
            :class="{ 'thumbnail-selected': image.image_url === mainImage }" class="thumbnail-image"
            @click="setMainImage(image.image_url)" />
        </div>
      </div>

      <div class="col-12 col-md-6 mb-2 right-side-info">
        <div class="col-12 text-center mb-4 mt-md-0 mt-4 d-none d-md-block">
          <h1 class="product-name text-md-start text-center">
            {{ product.name }}
          </h1>
        </div>

        <div class="col-12 text-center mb-4 mt-4 d-md-none">
          <h1 class="product-name-mobile text-start fs-1">
            {{ product.subCategory }} {{ product.name }}
          </h1>
        </div>
        <div class="d-md-flex mt-4 priceContainer">
          <div class=" h2 text-md-start mt-md-0 text-center">
            <div class="text-start user-product-price"
              style="color: black !important; font-size: 1.1rem; text-decoration: line-through;">
              {{ product.msrp.toFixed(2) }} €<span class="fw-normal">/vnt.</span>
            </div>
            <div class="note text-start">
              Naujo produkto kaina
            </div>
          </div>
          <div class="h2 text-md-start mt-2 mt-md-0 text-center priceInfoContainer">
            <div class="text-start user-product-price">
              {{ product.price.toFixed(2) }} €<span class="fw-normal" style="font-size: 1rem">/vnt.</span>
            </div>
            <div class="note text-start" style="color: #e85252; font-size: 12.4px">
              Sutaupykite {{ (product.msrp.toFixed(2) - product.price.toFixed(2)).toFixed(2) }} eur.
            </div>
          </div>
        </div>
        <div class="product-condition mt-2">
          <div class="product-condition-description">
            <span :style="product.condition.name === 'Turinti defektų' ? 'color: rgb(188, 22, 22);' : 'color: #2cb6d2;'"
              class="fw-bold" style="font-size: 18px">
              {{ product.condition.name }}
            </span>
            - {{ product.condition.description }}
          </div>
        </div>
        <p class="text-start" style="margin-top: 1rem; margin-bottom: 0; padding: 0;">
          Šiai prekei taikoma <span class="fw-bold">3 mėnesių</span> garantija.
        </p>
        <div class="d-flex">
          <button class="col-8 fs-md-4 revolt-add-to-cart" :style="product.productCarted
            ? { backgroundColor: '#2589aa' }
            : { backgroundColor: '#2cb6d2' }
            " @click="product.productCarted ? goToCart() : addToCart()">
            {{
              product.productCarted
                ? "Produktas jau jūsų krepšelyje"
                : "Į KREPŠELĮ"
            }}
          </button>
          <button id="favBtn" class="favor-btn col-3" :class="{ liked: product.is_liked }" @click="favorProduct">
            <i class="fas fa-heart"></i>
          </button>
        </div>
        <AddToCartModalComponent :show="showCartModal" :productName="product.name" @close="handleClose"
          @go-to-cart="goToCart" />
        <div class="container mt-4">
          <div class="row">
            <div class="delivery-method col-md-6 mb-4">
              <div class="card delivery-item">
                <div class="card-body d-flex align-items-center">
                  <div class="delivery-icon me-5 me-md-3">
                    <i class="fas fa-box"></i>
                  </div>
                  <div class="delivery-info">
                    <h5 class="deliver-option-title">Atsiėmimas paštomate</h5>
                    <p class="deliver-option-text">
                      Gaukite į paštomatą už <strong>0,00 €</strong>. Pristatysime
                      <span class="delivery-date">{{ deliveryDay }}</span>, 
                      <span class="delivery-date" style="color: rgb(102, 102, 102); font-size: 12px; white-space: nowrap">{{ deliveryDate }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="delivery-method col-md-6 mb-4 customWidth">
              <div class="card delivery-item">
                <div class="card-body d-flex align-items-center">
                  <div class="delivery-icon me-5 me-md-3">
                    <i class="fas fa-truck"></i>
                  </div>
                  <div class="delivery-info">
                    <h5 class="deliver-option-title">Pristatymas kurjeriu</h5>
                    <p class="deliver-option-text">
                      Gaukite į namus už <strong>0,00 €</strong>. Pristatysime
                      <span class="delivery-date">{{ deliveryDay }}</span>,
                      <span class="delivery-date" style="color: rgb(102, 102, 102); font-size: 12px; white-space: nowrap">{{ deliveryDate }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="product-description mt-4 d-md-none">
          <span class="product-condition-title">Apie prekę:</span>
          {{ product.mainDescription }}
        </div>

        <div class="product-description-table mt-3 text-start text-md-center d-md-none">
          <table v-if="product.generalDetails.length" class="product-table styled-table mt-3 col-12">
            <thead>
              <tr>
                <th class="product-spec-table-header text-center p-2" colspan="2">
                  Bendrosios charakteristikos
                </th>
              </tr>
            </thead>
            <tbody class="product-specs">
              <tr v-for="(row, index) in product.generalDetails" :key="index">
                <td>{{ row.feature }}</td>
                <td>{{ row.value }}</td>
              </tr>
              <tr>
                <th class="product-spec-table-header text-center p-2" colspan="2">
                  Techniniai duomenys
                </th>
              </tr>
              <tr v-for="(row, index) in product.attributes" :key="index">
                <td>{{ row.attribute_name }}</td>
                <td>{{ row.attribute_value }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="relatedProducts.length > 2">
            <div class="mt-4 mb-2 fw-bold">Lankytojai taip pat domėjosi:</div>
            <div class="related-product-container col-12">
              <ProductCardComponent v-for="product in relatedProducts" :key="product.id" :product="product"
                :RelatedProductsMobileView="true" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-none d-md-block">
        <div class="product-description mt-1 d-none d-md-block">
          <span class="product-condition-title">Apie prekę:</span>
          {{ product.mainDescription }}
        </div>

        <div class="product-description-table mt-3 text-start text-md-center d-none d-md-block">
          <div class="row">
            <table v-if="product.generalDetails.length" class="product-table styled-table mt-3 col-12 col-md-6 p-1">
              <thead>
                <tr>
                  <th class="product-spec-table-header text-center p-2" colspan="2">
                    Bendrosios charakteristikos
                  </th>
                </tr>
              </thead>
              <tbody class="product-specs">
                <tr v-for="(row, index) in product.generalDetails" :key="index">
                  <td>{{ row.feature }}</td>
                  <td>{{ row.value }}</td>
                </tr>
              </tbody>
            </table>

            <table v-if="product.attributes.length" class="product-table styled-table mt-3 col-12 col-md-5 p-2">
              <thead>
                <tr>
                  <th class="product-spec-table-header text-center p-2" colspan="2">
                    Techniniai duomenys
                  </th>
                </tr>
              </thead>
              <tbody class="product-specs">
                <tr v-for="(row, index) in product.attributes" :key="index">
                  <td>{{ row.attribute_name }}</td>
                  <td>{{ row.attribute_value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="relatedProducts.length > 2">
            <div class="mt-4 fw-bold text-start" style="font-size: 20px">
              Lankytojai taip pat domėjosi
            </div>
            <div class="related-product-container col-12">
              <ProductCardComponent v-for="product in relatedProducts" :key="product.id" :product="product"
                :DisplayRelatedProduct="true" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for enlarged image with transition animation -->
    <div v-if="isImageModalOpen" class="image-modal-overlay" @click="closeImageModal">
      <div class="image-modal-content expand-animation">
        <img :src="mainImage" alt="Enlarged Product Image" class="enlarged-image" />
        <button class="close-modal-btn" @click="closeImageModal">×</button>
      </div>
    </div>
  </div>
  <ProductSoldComponent v-else-if="isSold" />

  <div v-else class="loading-screen" style="
    /* height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 5rem;
  ">
    <div class="spinner"></div>
    <p style="margin-top: 20px; padding: 2rem;">
      Produkto duomenys kraunami, prašome palaukti...
    </p>
  </div>
</template>

<script>
import ProductCardComponent from "../ProductCardComponent.vue";
import AddToCartModalComponent from "./AddToCartModalComponent.vue";
import ProductSoldComponent from "./ProductSoldComponent.vue";
import axios from "axios";
import EventBus from "@/eventBus";
import getAuthHeaders from "@/utility/sessionId";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "ProductPageComponent",
  components: {
    ProductCardComponent,
    AddToCartModalComponent,
    ProductSoldComponent
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSold: false,
      showCartModal: false,
      buttonDisabled: true,
      product: null,
      mainImage: null,
      isImageModalOpen: false,
      relatedProducts: [],
      deliveryDay: "",
      deliveryDate: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.calculateDeliveryDay();
    const token = localStorage.getItem("userToken");
    if (!token) {
      this.buttonDisabled = true;
    } else {
      this.buttonDisabled = false;
    }
    this.fetchProduct();

    const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
    if (guestCart.some((cartItem) => cartItem.id === this.id)) {
      this.product.productCarted = true;
    }
  },
  methods: {
    trackProductView() {
      // Ensure Google Analytics gtag is available
      if (typeof gtag === "function" && this.product) {
        gtag("event", "view_item", {
          items: [
            {
              id: this.product.id,
              name: this.product.name,
              category: this.product.subCategory || "Nežinoma kategorija",
              price: this.product.price,
            },
          ],
          currency: "EUR",
          value: this.product.price,
        });
        console.log("Product view event sent to Google Analytics");
      } else {
        console.warn("Google Analytics (gtag) not initialized or product data missing.");
      }
    },
    setMainImage(imageUrl) {
      this.mainImage = imageUrl;
    },
    calculateDeliveryDay() {
      const weekdays = [
        "pirmadienį",
        "antradienį",
        "trečiadienį",
        "ketvirtadienį",
        "penktadienį",
      ];
      const currentDate = new Date();
      let daysToAdd = 7;

      while (daysToAdd > 0) {
        currentDate.setDate(currentDate.getDate() + 1);
        const dayOfWeek = currentDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          // Skip weekends (0 = Sunday, 6 = Saturday)
          daysToAdd--;
        }
      }
      // Format the date as yyyy-mm-dd
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      // Get the final delivery day in Lithuanian
      const dayOfWeekIndex = currentDate.getDay() - 1; // Adjusting for weekdays array (Monday = 0)
      const weekday = weekdays[dayOfWeekIndex < 0 ? 4 : dayOfWeekIndex]; // Handle Sunday edge case

      // Combine the weekday and the formatted date
      this.deliveryDay = `${weekday}`;
      this.deliveryDate = `${formattedDate}`;
    },
    fetchProduct() {
      const headers = getAuthHeaders();

      axios
        .get(`${API_BASE_URL}/product/${this.id}`, { headers })
        .then((response) => {
          this.product = response.data;

          this.mainImage = this.product.images.find((img) => img.is_main_image)?.image_url || this.product.images[0]?.image_url;
          this.product.productCarted = response.data.productCarted || false;
          document.title = `Revolt.lt | ${this.product.name}`;
          this.fetchRelatedProducts();
          this.trackProductView();
        })
        .catch((error) => {
          console.error("Error fetching product:", error);
          this.isSold = true;
        });
    },
    openImageModal() {
      this.isImageModalOpen = true;
    },
    closeImageModal() {
      this.isImageModalOpen = false;
    },
    handleClose() {
      this.showCartModal = false;
    },
    favorProduct() {
      const token = localStorage.getItem("userToken");

      if (!token) {
        // Redirect to login page if token is missing
        this.$router.push({ name: "Login" });
        return;
      }

      axios
        .post(
          `${API_BASE_URL}/product/${this.id}/like`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.product.is_liked = !this.product.is_liked;
          }
        })
        .catch((error) => {
          console.error("Error favoriting product:", error);
        });
    },
    addToCart() {
      const headers = getAuthHeaders();
      axios
        .post(
          `${API_BASE_URL}/add-to-cart/${this.product.id}`,
          {}, // Empty body
          { headers } // Headers go here
        )
        .then((response) => {
          if (response.status === 200) {
            this.product.productCarted = true;
            this.showCartModal = true;
            EventBus.emit("cartUpdated");
            this.trackAddToCart();
          }
        })
        .catch((error) => {
          console.error("Error adding product to cart:", error.response?.data || error);
        });
    },
    trackAddToCart() {
      // Check if gtag is defined
      /* global gtag */
      if (typeof gtag === "function") {
        gtag("event", "add_to_cart", {
          items: [
            {
              id: this.product.id,
              name: this.product.name,
              category: this.product.subCategory || "Nežinoma kategorija",
              price: this.product.price,
              quantity: 1,
            },
          ],
          currency: "EUR",
          value: this.product.price,
        });
      } else {
        console.warn("Google Analytics (gtag) not initialized.");
      }
    },
    goToCart() {
      this.$router.push({ name: "Cart" });
    },
    fetchRelatedProducts() {
      if (
        !this.product ||
        !this.product.category.subCategory_id ||
        !this.product.category.category_id
      ) {
        console.error(
          "Related products cannot be fetched: product or category is undefined."
        );
        return;
      }

      const categoryId = this.product.category.category_id;
      const productId = this.product.id;

      axios
        .get(
          `${API_BASE_URL}/category/${categoryId}/product/${productId}/related-products`
        )
        .then((response) => {
          this.relatedProducts = response.data;
        })
        .catch((error) => {
          console.error("Error fetching related products:", error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "/src/styles/producPage.scss";

.image-thumbnails {
  opacity: 0.7;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* Adds spacing around thumbnails */
  background-color: #f8f9fa;
  /* Light background for better visibility */
  border-radius: 8px;
  /* Rounds the corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
}

.thumbnail-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
}

.thumbnail-selected {
  border-color: #2cb6d2;
}


.product-name-mobile {
  font-weight: 800 !important;
}

.priceContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.desktopMargins {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {


  .desktopMargins {
    margin-top: 3rem;
  }


  .customWidth {
    padding-right: 0px;
  }

}

@media (max-width: 768px) {
  .priceContainer {
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }
}

.pi-styled {
  padding: 1rem 1rem;
  /* 10rem for top and bottom, 1rem for left and right */
}

/* Modal styles for enlarged image with animation */
.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-modal-content {
  position: relative;
  opacity: 0;
  transform: scale(0.5);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.expand-animation {
  opacity: 1;
  transform: scale(1);
}

.enlarged-image {
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
}

.close-modal-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #2cb6d2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.revolt-add-to-cart {

  margin-top: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;

  background-color: #2cb6d2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    background-color: #2baec9;
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  &.disabled {
    background-color: #207895;
    color: white;
  }
}

@media (min-width: 1600px) {
  .product-page {
    padding: 2.4rem !important;
  }
}

.priceInfoContainer {
  width: 50%;
  margin-top: -6px !important;
}

@media (max-width: 800px) {
  .priceInfoContainer {
    margin-top: auto !important;
  }


  .right-side-info {
    margin-top: 0.2rem
  }

  .product-page {
    padding: 1.4rem !important;
  }
}



.favor-btn {
  margin-top: 1rem;

  &:hover {
    background-color: white;
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  &.liked {
    color: #2cb6d2;
  }

  &.disabled {
    background-color: #f5f5f5;
    color: #ccc;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.disabled:hover,
  &.disabled:active {
    box-shadow: none;
    transform: none;
    background-color: #f5f5f5;
  }
}
</style>
