<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <!-- Logo and Description -->
        <div class="col-12 col-md-4 mb-4 mb-md-0">
          <img class="revolt-logo-with-header" :src="logoUrl" alt="ReVolt Logo" />
        </div>

        <!-- Useful Links -->
        <div class="col-12 col-md-4 mb-4 mb-md-0">
          <ul class="footer-links list-unstyled">
            <li>
              <a class="footerLink" @click="goToPurchasePolicy">Pirkimo ir pardavimo taisyklės</a>
            </li>
            <li>
              <a class="footerLink" @click="goToProductConditionDescriptions()">Prėkių būklių aprašymai</a>
            </li>
            <li>
              <a class="footerLink" @click="goToContactUs">Kontaktai/rekvizitai</a>
            </li>
            <li>
              <a class="footerLink" @click="goToAboutUs">Apie mus</a>
            </li>
          </ul>
        </div>

            <div class="col-12 col-md-4 mb-4 mb-md-0">
          <ul class="footer-links list-unstyled">
            <li>
              <a class="footerLink" @click="goToDeliveryTerms">Pristatymo sąlygos</a>
            </li>
            <li>
              <a class="footerLink" @click="goToReturnPolicy">Gražinimo sąlygos</a>
            </li>
            <li>
              <a class="footerLink" @click="goToPrivacyPolicy">Privatumo politika</a>
            </li>
          </ul>
        </div>

        <!-- Social Media -->
        <div class="col-12 col-md-4" style="display: none;">
          <h4 class="footer-heading">Follow Us</h4>
          <ul class="social-icons list-unstyled d-flex">
            <li>
              <a href="#" class="social-icon"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
              <a href="#" class="social-icon"><i class="fab fa-instagram"></i></a>
            </li>
            <li>
              <a href="#" class="social-icon"><i class="fab fa-linkedin-in"></i></a>
            </li>
          </ul>
        </div>
      </div>

      <hr/>

      <div class="row mt-4">
        <div class="col-12 text-center">
          <div class="d-flex flex-wrap justify-content-center align-items-center logo-container">
            <div class="logo-box">
              <img src="https://bank.paysera.com/assets/image/payment_types/wallet.png" alt="Partner 2 Logo" class="partner-logo" />
            </div>
            <div class="logo-box">
              <img src="https://cdn.revolt.lt/swed.gif" alt="Partner 1 Logo" class="partner-logo" />
            </div>
            <div class="logo-box">
              <img src="https://bank.paysera.com/assets/image/payment_types/lt_seb_pisp.png" alt="Partner 3 Logo" class="partner-logo" />
            </div>
            <div class="logo-box">
              <img src="https://bank.paysera.com/assets/image/payment_types/lt_dnb_pisp.png" alt="Partner 4 Logo" class="partner-logo" />
            </div>
            <div class="logo-box">
              <img src="https://bank.paysera.com/assets/image/payment_types/revolut.png" alt="Partner 4 Logo" class="partner-logo" />
            </div>
            <div class="logo-box">
              <img src="https://bank.paysera.com/assets/image/payment_types/sb.png" alt="Partner 4 Logo" class="partner-logo" />
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12 text-center" style="display: none;">
          <p class="footer-copyright">© 2024 Revolt. Visos teisės saugomos.</p>
          <p class="footer-copyright fw-bold">MB "Pirmi kartai"</p>
        </div>
      </div>
    </div>
  </footer>
</template>


<script>
export default {
  name: "FooterComponent",
  methods: {
    goToProductConditionDescriptions() {
      this.$router.push({ name: "ProductConditionDescriptions" });
    },
    goToPrivacyPolicy() {
      this.$router.push({ name: "PrivacyPolicy" });
    },
    goToAboutUs() {
      this.$router.push({ name: "AboutUs" });
    },
    goToContactUs() {
      this.$router.push({ name: "ContactUs" });
    },
    goToDeliveryTerms() {
      this.$router.push({ name: "DeliveryTerms" });
    },
    goToReturnPolicy() {
      this.$router.push({ name: "ReturnPolicy" });
    },
    goToPurchasePolicy() {
      this.$router.push({ name: "PurchasePolicy" });
    },
  },
  data() {
    return {
      logoUrl: require("@/assets/revolot_logo_with_slogan.png"),
    };
  },
};
</script>

<style lang="scss">
@import "/src/styles/footer.scss";

.footerLink{
  color: white;
}

.footerLink:hover{
  color: rgb(238, 215, 215) !important;
  text-decoration: none !important;
  cursor: pointer;
}

/* Sticky footer styling */
html, body, #app {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
}

.footer {
  background-color: $primary-revolt-color;
  color: white;
  padding: 2rem 0;
  text-align: center;
  flex-shrink: 0;
}

.logo-container {
  gap: 1.5rem;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

// .logo-box {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0.5rem;
//   width: 120px; /* Adjust width based on logo sizes */
//   height: 70px; /* Adjust height for consistent alignment */
//   background-color: #fff; /* Optional: add a background */
//   border-radius: 8px; /* Optional: for rounded corners */
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
//   overflow: hidden;
  
// }


.logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  width: 120px; /* Adjust width based on logo sizes */
  height: 70px; /* Adjust height for consistent alignment */
  background-color: #fff; /* Optional: add a background */
  border-radius: 8px; /* Optional: for rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
  overflow: hidden;
  position: relative; /* Ensure positioning for the pseudo-element */
}

.logo-box img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.logo-box::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 182, 210, 0.1); /* #2cb6d2 with 50% transparency */
  pointer-events: none; /* Ensure overlay doesn't block interactions */
}


.partner-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures logos scale proportionally */
  padding: 10px
}

</style>
