<template>
  <div class="terms-container">
    <!-- Page Header -->
    <div class="header-section">
      <h1 style="font-weight: normal;">Pirkimo ir Pardavimo Taisyklės</h1>
      <p>Sužinokite, kaip lengvai ir saugiai apsipirkti mūsų platformoje.</p>
    </div>

    <!-- Timeline Layout -->
    <div class="timeline">
      <!-- Step 1 -->
      <div class="timeline-item">
        <div class="timeline-icon">
          <i class="fa fa-user-plus"></i>
        </div>
        <div class="timeline-content">
          <h3>Registracija ir Užsakymo Patvirtinimas</h3>
          <p>
            Norint atlikti pirkimą, rekomenduojame susikurti paskyrą. Kai jūsų užsakymas bus patvirtintas,
            gausite el. laišką su visomis detalėmis.
          </p>
        </div>
      </div>

      <!-- Step 2 -->
      <div class="timeline-item">
        <div class="timeline-icon">
          <i class="fa fa-credit-card"></i>
        </div>
        <div class="timeline-content">
          <h3>Apmokėjimas</h3>
          <p>
            Apmokėjimą galite atlikti mūsų siūlomais būdais. Mokėjimų saugumas užtikrinamas laikantis privatumo
            politikos.
          </p>
        </div>
      </div>

      <!-- Step 3 -->
      <div class="timeline-item">
        <div class="timeline-icon">
          <i class="fa fa-cube"></i>
        </div>
        <div class="timeline-content">
          <h3>Prekių Prieinamumas</h3>
          <p>
            Visi produktai yra unikalūs ir gali būti riboti. Jei prekės nebus prieinamos, susisieksime dėl alternatyvų
            arba pinigų grąžinimo.
          </p>
        </div>
      </div>

      <!-- Step 4 -->
      <div class="timeline-item highlight">
        <div class="timeline-icon">
          <i class="fa fa-undo"></i>
        </div>
        <div class="timeline-content">
          <h3>Garantijos ir Grąžinimo Politika</h3>
          <p>
            Visiems produktams taikoma <strong>dviejų savaičių grąžinimo politika</strong> nuo pristatymo dienos. Dėl
            garantijų ir grąžinimų kreipkitės į klientų aptarnavimą.
          </p>
        </div>
      </div>

      <!-- Step 5 -->
      <div class="timeline-item">
        <div class="timeline-icon">
          <i class="fa fa-truck"></i>
        </div>
        <div class="timeline-content">
          <h3>Atsakomybė</h3>
          <p>
            Mes neatsakome už vėlavimus ar nuostolius, atsiradusius dėl logistikos problemų ar aplinkybių, kurių
            nekontroliuojame.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PurchasePolicy",
  mounted() {
    window.scrollTo(0, 0); // Scroll to the top when the page loads
  },
};
</script>

<style scoped lang="scss">
@import "/src/styles/variable";

.terms-container {
  padding: 2rem 1rem;
  max-width: 900px;
  margin: 0 auto;

  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      font-size: 2rem;
      color: #2cb6d2;
      font-weight: bold;
    }

    p {
      color: #555;
      font-size: 1.1rem;
    }
  }

  .timeline {
    position: relative;
    padding: 0;
    list-style: none;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      width: 4px;
      height: 100%;
      background-color: #e0e0e0;
      transform: translateX(-50%);
    }

    .timeline-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 2rem;
      position: relative;

      &:nth-child(even) {
        flex-direction: row-reverse;

        .timeline-content {
          text-align: right;
        }
      }

      .timeline-icon {
        background-color: #2cb6d2;
        color: #fff;
        font-size: 1.5rem;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
        position: relative;
        z-index: 10;
        margin: 0 1rem;
      }

      @media (max-width: 768px) {
        .timeline-icon {
          top: 30px;
          left: -27px;
        }

        .timeline-content {

          text-align: center !important;
        }

      }


      .timeline-content {
        background-color: #fff;
        padding: 1.5rem;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        max-width: 400px;

        h3 {
          font-size: 1.3rem;
          color: #2cb6d2;
          margin-bottom: 0.5rem;
        }

        p {
          color: #555;
          line-height: 1.6;
        }
      }

      &.highlight {
        .timeline-content {
          border-left: 4px solid #2cb6d2;
          background-color: #eaf8fc;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .timeline::before {
    left: 2rem;
    transform: none;
  }

  .timeline-item {
    flex-direction: column !important;

    .timeline-icon {
      margin: 0 auto 1rem;
    }

    .timeline-content {
      text-align: center;
      margin: 0 auto;
    }
  }
}
</style>
